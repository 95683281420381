import { RouteRecordRaw } from "vue-router"

import Home from "@/views/Home.vue"
import auth from "@/middleware/auth"
import guest from '@/middleware/guest'
import firstAccess from "@/middleware/firstAccess"
import notFirstAccess from "@/middleware/notFirstAccess"
import isRecrutador from "@/middleware/isRecrutador"
import isCandidato from "@/middleware/isCandidato"
import redirectHome from "@/middleware/redirectHome"

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        meta: {
            middleware: [ guest ]
        },
        component: Home
    },
  
    {
        path: '/teste',
        meta: {
            middleware:  [ auth, notFirstAccess ]
        },
        component: () => import('@/views/Teste.vue')
        
    }, 
    {
        path: '/testesf',
        name: 'facebook',
        component: () => import('@/views/auth/facebook.vue')
        
    }, 
    {
        path: '/login',
        name: 'login_candidato',
        meta: {
            middleware: [ guest ]
        },
        component: () => import('@/views/auth/Login.vue'),
        children: [
            {
                path: '',
                name: 'candidato-login',
                component: () => import('@/views/auth/LoginForm.vue')
            },
            {
                path: 'esqueceu-a-senha',
                name: 'esqueceu-a-senha',
                component: () => import('@/views/auth/EnviarEmailRecuperacao.vue')
            }
        ]
    },
    {
        // essa rota não existe, serve apenas para direcionar o usuário logado a sua home correspondente
        path: '/',
        name: 'home-usuario',
        meta: {
            middleware: [ auth, redirectHome ]
        },
        component: () => import('@/views/usuario/HomeUsuario.vue'),
        children: [
            {
                path: '/candidato/home',
                name: 'home-candidato',
                meta: {
                    middleware: [ firstAccess, isCandidato]
                },
                component: () => import('@/views/candidato/HomeCandidato.vue')
            },
            {
                path: '/recrutador/home',
                name: 'home-recrutador',
                meta: {
                    middleware: [ firstAccess, isRecrutador ]
                },
                component: () => import('@/views/recrutador/HomeEmpresa.vue')
            },
            {
                path: '/usuario/perfil',
                name: 'perfil-usuario',
                meta: {
                    middleware: [ auth ]
                },
                component: () => import('@/views/usuario/PerfilUsuario.vue')
            }
        ]
    },
    {
        path: '/recrutador/login',
        name:'login_recrutador',
        meta: {
            middleware: [ guest ]
        },
        component: () => import('@/views/auth/Login.vue'),
        children: [
            {
                path: '',
                name: 'recrutador-login',
                component: () => import('@/views/auth/LoginForm.vue')
            },
            {
                path: 'esqueceu-a-senha',
                name: 'recrutador-esqueceu-a-senha',
                component: () => import('@/views/auth/EnviarEmailRecuperacao.vue')
            }
        ]
    },
    {
        path: '/auth/facebook/callback',
        name: 'loginFacebook',
        meta: {
            middleware: [ guest ]
        },
        component: () => import('@/views/loginFacebook.vue'),
    },
    {
        path: '/auth/google/callback',
        name: 'loginGoogle',
        meta: {
            middleware: [ guest ]
        },
        component: () => import('@/views/loginGoogle.vue'),
    },
    {
        path: '/auth/linkedin/callback',
        name: 'loginLinkedin',
        meta: {
            middleware: [ guest ]
        },
        component: () => import('@/views/loginLinkedin.vue')
    },
    {
        path: '/cadastro-candidato',
        name: 'cadastro-candidato',
        meta: {
            middleware: [ guest ]
        },
        component: () => import('@/views/CadastroCandidato.vue'),
    },
   
    {
        path: '/cadastro-recrutador',
        name: 'cadastro-recrutador',
        meta: {
            middleware: [ guest ]
        },
        component: () => import('@/views/CadastroCandidato.vue'),
    },
    {
        path: '/concluir-cadastro-candidato/:uuid/token/:token',
        name: 'fim-cadastro',
        component: () => import('@/views/candidato/ConclusaoCadastro.vue')
    },
    {
        path: '/user/email/validar/verificar/:id/:hash/',
        name: 'verify-email',
        meta: {
            middleware: [ auth ]
        },
        component: () => import('@/views/auth/ValidarEmail.vue')
    },
    {
        path: '/concluir-cadastro-recrutador/:uuid/token/:token',
        name: 'fim-cadastro-recrutador',
        component: () => import('@/views/candidato/ConclusaoCadastro.vue')
    },
    {
        path: '/finalizar-cadastro',
        name: 'finalizar-cadastro',
        component: () => import('@/views/candidato/steps/TerminoCadastro.vue')
    },
    {
        path: '/finalizar-cadastro-recrutador',
        name: 'finalizar-cadastro-recrutador',
        component: () => import('@/views/candidato/steps/TerminoCadastro.vue')
    },
    
    {
        path: '/error',
        name: 'error-404',
        meta: {
            middleware: []
        },
        component: () => import('@/views/errors/404.vue')
    },
    {
        path: '/recrutador/empresa',
        name: 'first-cadastro-recrutador',
        meta: {
            middleware: [ auth, notFirstAccess,isRecrutador ]
        },
        component: () => import('@/views/recrutador/CadastrarEmpresa.vue')
    },
    {
        path: '/candidato/selecionar-cargos',
        name: 'selecionar-cargos',
        meta: {
            middleware: [ auth, notFirstAccess ]
        },
        component: () => import('@/views/candidato/informacoesComplementares/wizard.vue')
    },
    {
        path: '/recuperar-senha/:uuid/token/:token',
        name: 'recuperar-senha',
        meta: {
            middleware: [ guest ]
        },
        component: () => import('@/views/auth/RecuperarSenha.vue')
    },
]

export default routes