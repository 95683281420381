import { Profile } from "@/enums/profileEnum";
import { useAuthStore } from "@/stores/authStore";
import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

export default function guest(next: NavigationGuardNext, to: RouteLocationNormalized, from: RouteLocationNormalized) {
    const authStore = useAuthStore()

    if (authStore.authUser.token) {
        return next({
            name: 'home-usuario'
        })
    }

    return next()
}