import { Profile } from "@/enums/profileEnum";
import { useAuthStore } from "@/stores/authStore";
import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

export default function isRecrutador(next: NavigationGuardNext, to: RouteLocationNormalized, from: RouteLocationNormalized) {
    const authStore = useAuthStore()
    
    if (authStore.authUser.profile.id != Profile.RECRUTADOR) {
        return next({
            name: 'home-candidato'
        })
    }

    return next()
}