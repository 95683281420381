import { RouteRecordRaw } from "vue-router";

const errorRoutes: Array<RouteRecordRaw> = [
    // 404 page not found
    {
        path: "/:pathMatch(.*)*",
        name: "not-found",
        component: () => import("@/views/errors/404.vue")
    }
]   

export default errorRoutes