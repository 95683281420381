import { useAuthStore } from "@/stores/authStore";
import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

export default function auth(next: NavigationGuardNext, to: RouteLocationNormalized, from: RouteLocationNormalized) {
    const authStore = useAuthStore() 

    if (!authStore.authUser.token) {

        if (to.meta.middleware.find(mid => mid.name === 'isCandidato')) {
            return next({
                name: 'candidato-login'
            })
        } else if (to.meta.middleware.find(mid => mid.name === 'isRecrutador')) {
            return next({
                name: 'recrutador-login'
            })
        } else {
            return next({
                name: 'home-usuario'
            }) 
        }
    }

    return next()
}