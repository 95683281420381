import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

export type MiddlewareType = (next: NavigationGuardNext, to: RouteLocationNormalized, from: RouteLocationNormalized) => void // | undefined

const middlewarePipeline = function (middlewares: Array<MiddlewareType>, next: NavigationGuardNext, to: RouteLocationNormalized, from: RouteLocationNormalized) {
    const middlewaresLeft = middlewares.slice(0);
    const nextMiddleware = middlewaresLeft.shift();

    if (!nextMiddleware) {
        next()
    } else {
        nextMiddleware((nextArg?) => {
            if (!nextArg) {
                return middlewarePipeline(middlewaresLeft, next, to, from)
            }

            return next(nextArg)
        }
        , to, from,)
    }
}

export default middlewarePipeline;
