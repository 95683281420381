<template>
  <div class="card card-vaga m-1 bg-default shadow-lg">  
    <!-- HEADER -->
    <div class="card-header row p-0">
      <div class="col-lg-12 col-xl-12 d-flex justify-content-end"> 
        <label style="margin-right: 10px;">vaga expiradá em {{ vagaStore.diferencaEmDias(props.vaga.dt_inicio , props.vaga.dt_fim)  }} dias...</label>
      </div>
      <div class="col-12"> 
        <h4 style="margin-left: 10px;">{{props.vaga.titulo}}</h4>
      </div>
    </div>

    <!-- BODY -->
    <div class="card-body">
      <div class="row"> 
        <div class="col-12">
          <div class="d-flex align-items-center">  
            <span style="font-size: 35px;" class="mdi mdi-thumb-up-outline"></span>
            <label style="font-size: 25px; margin-left: 10px; margin-right: 35px;">10</label> 
            <label style="font-size: 15px;">Interessados (a) na vaga</label>  
          </div>
          <div class="d-flex align-items-center">  
            <span style="font-size: 35px;" class="mdi mdi-heart-outline"></span>
            <label style="font-size: 25px; margin-left: 10px; margin-right: 35px;">10</label> 
            <label style="font-size: 15px;">Interessados (a) na vaga</label>  
          </div>
        </div>
      </div>
    </div> 
    <!-- FOTTER -->
    <div class="card-footer" >
      <div class="d-flex justify-content-between align-items-end w-100">
        <div>
          <div class="badge badge-info">
            {{ props.vaga.codigo_vaga }}
          </div>
        </div>
        <div
          class="d-flex gap-1"
          v-if="authStore.authUser.profile && authStore.authUser.profile.id == Profile.RECRUTADOR"
        >
          <button class="btn btn-sm btn-success">
            <span class="mdi mdi-magnify" />
          </button> 
          <button
            class="btn btn-sm btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            @click="findVaga(vaga.id ?? 0)"
          >
            <span  class="mdi mdi-pencil-outline" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
// import { mdiAccount, mdiEmail } from "@mdi/js";
import { Profile } from '@/enums/profileEnum'
import { IVaga } from '@/interfaces/VagaInterface'
import { useAuthStore } from '@/stores/authStore'
import { useVagaStore } from '@/stores/vagaStore'

import { ref } from 'vue'
const authStore = useAuthStore()
const vagaStore = useVagaStore()
const url = import.meta.env.VITE_URL_STORAGE
const showText = ref<boolean>(false)
function findVaga (id: number) {
  if (id === 0) {
    vagaStore.vaga = {} as IVaga
  } else {
    vagaStore.findVaga(id)
  }
}

const props = defineProps<{
  vaga: IVaga;
}>()
const vagaAvatar =
  'https://ui-avatars.com/api/?rounded=true&background=random&size=200&name=' +
  props.vaga.titulo
</script>

<style lang="css">
.text-container {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.animate {
  opacity: 1;
}
.card-vaga {
  /* max-width: 350px; */
  min-height: 250px !important;
}

.logo-card-vaga {
  width: 120px;
}

.descricao-vaga {
  font-size: 0.9rem;
  /* text-align: justify; */
  /* overflow: hidden;
  text-overflow: ellipsis;
  max-height: 4.2rem; */
}

.vaga-titulo {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
