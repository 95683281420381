import { Ref, computed, ref, watch } from "vue";
import { defineStore } from "pinia";
import { IEstado } from "@/interfaces/EstadoInterface";
import { ICidade } from "@/interfaces/CidadeInterface";
import { ICargo } from "@/interfaces/CargoInterface";
import { IArea } from "@/interfaces/AreaInterface";
import { ISegmento } from "@/interfaces/SegmentoInterface";
import endereco from "@/services/enderecoService";
import CargoService from "@/services/cargoService";
import area from "@/services/areaService";
import segmento from "@/services/segmentoService";
import { IFiltroVaga, IVaga } from "@/interfaces/VagaInterface";
import vagaService from "@/services/vagaService";
import { AxiosError, AxiosResponse } from "axios";
import { IBairro, ICep, IEndereco, IEnderecoApi, ILogradouro, IUserEndereco } from "@/interfaces/EnderecoInterface";
import Swal from "sweetalert2";
import { LocalVagaEnum } from "@/enums/localVagaEnum";
import { IImageApi } from "@/interfaces/ImageInterface";
import { differenceInDays, parseISO } from "date-fns"; // Importando as funções necessárias do date-fns
import { IDefault } from "@/interfaces/SelectDefaultInterface";


export interface WizardState {
    localTrabalho:Array<IEstado>
}
export interface IOpcoesFiltro {
    estados: Array<IEstado>,
    mostrarLogo:Array<IDefault>,
    cidades: Array<ICidade>,
    cargos: Array<ICargo>,
    areas: Array<IArea>,
    localVagas: Array<IEstado>,
    segmentos: Array<ISegmento>
}
 
export interface ILoading {
    cidade: boolean
}

export const newVaga = (): IVaga => {
    let vaga = {} as IVaga
    vaga.endereco = {
        endereco: {
            cep: {} as ICep,
            logradouro: {} as ILogradouro,
            bairro: {} as IBairro,
            cidade: {} as ICidade,
            estado: {} as IEstado
        } as IEndereco,
    } as IUserEndereco

    return vaga
}

export const useVagaStore = defineStore('vagaStore', () => {
    const formData = new FormData();
    const vaga: Ref<IVaga> = ref<IVaga>(newVaga());
    const vagaAvatar: Ref<string> = ref<string>(vaga.value.avatar?.url|| `https://ui-avatars.com/api/?rounded=true&background=random&size=32&name=${vaga.value.titulo}`);
    async function findVaga(id: number): Promise<void> {
        vaga.value = (await vagaService.find(id)).data.data
        vagaAvatar.value = vaga.value.avatar?.url || `https://ui-avatars.com/api/?rounded=true&background=random&size=32&name=${vaga.value.titulo}`;
    }     
    async function uploadAvatar(id:number): Promise<AxiosResponse<IImageApi>> {   
        formData.append('id', `${vaga.value.id}` ?? id);
        const promiseAvatar = await vagaService.uploadAvatar(formData);    
        return promiseAvatar;
    }
    function diferencaEmDias(dt_inicio:string , dt_fim:string) { 
        const inicio = parseISO(dt_inicio);
        const fim = parseISO(dt_fim);
        const diferencaEmDias = differenceInDays(fim, inicio);
        return diferencaEmDias;
    }
    async function save(): Promise<void> { 
        vagaService.create(vaga.value).then(response => {     
            uploadAvatar(response.data.id).then(
            (responseAvatar:AxiosResponse<IImageApi>) => {    
                Swal.fire({
                    title: 'OK',
                    text: 'Vaga salvo com sucesso',
                    icon: 'success',
                    confirmButtonText: 'Continuar', 
                }) 
            } 
            ).catch((error: AxiosError) => { 
                Swal.fire({
                    title: 'OK',
                    text: 'Vaga salvo com sucesso',
                    icon: 'success',
                    confirmButtonText: 'Continuar', 
                }) 
            })
            
            vaga.value = {} as IVaga;
            getVagasEmpresa();
        }).catch(error => { 
            Swal.fire({
                title: 'Oops...',
                text: error.response.data.message,
                icon: 'error',
                confirmButtonText: 'Continuar',  
            })
        })
    }
    async function getCargos(): Promise<void> {
        filtros.value.cargos = (await CargoService.index()).data.data
    } 
    const filtros: Ref<IOpcoesFiltro> = ref<IOpcoesFiltro>({
        estados: [],
        mostrarLogo: [],
        cidades: [],
        cargos: [],
        areas: [],
        segmentos: [],
        localVagas:[]
    })
     
    const filtroSelecionado: Ref<IFiltroVaga> = ref<IFiltroVaga>({
        estado_id: null,
        cidade_id: null,
        cargo_id: null,
        area_id: null,
        segmento_id: null,
    })

    const vagas: Ref<Array<IVaga>> = ref<Array<IVaga>>([]) 
 
    const loading: Ref<ILoading> = ref<ILoading>({
        cidade: false
    })
    const getCreateAtFormat = (date: Date): string => {  
        return new Date(date).getUTCDay() + '/' + (new Date(date).getMonth() + 1) + '/' + new Date(date).getFullYear();
    }
    const getTextoLocalVaga = (id: number): string => {
        const vaga = vagas.value.find((vaga) => vaga.id === id)
       
        switch (vaga?.local_vaga) {
            case LocalVagaEnum.REMOTO:
                return 'Remoto'
        
            case LocalVagaEnum.PRESENCIAL:        
            case LocalVagaEnum.HIBRIDO: 
                if (vaga.endereco?.endereco) { 
                    return `${vaga.endereco.endereco.cidade.nome} / ${vaga.endereco.endereco.estado.sigla}`
                }
        
            default:
                return ''
        }
    }

    async function getEstados(): Promise<void> {
        filtros.value.estados = (await endereco.getEstados()).data.data
    }
    async function getMostrarLogo():Promise<void> {
        filtros.value.mostrarLogo = (await vagaService.getMostrarLogo()).data.data
    }
    async function getCidades(estado: number): Promise<void> {
       filtros.value.cidades = (await endereco.getCidades(estado)).data.data
    }
     
    async function getAreas(): Promise<void> {
        filtros.value.areas = (await area.index()).data.data
    }
    
    async function getSegmentos(): Promise<void> {
        filtros.value.segmentos = (await segmento.index()).data.data
    }
    async function getLocalVagas(): Promise<void> {
        filtros.value.localVagas = [];
    }
    async function getVagas(filtros?: IFiltroVaga): Promise<void> {
        vagas.value = (await vagaService.index(filtros)).data.data
    } 
    async function getVagasEmpresa(): Promise<void> {
        vagas.value = (await vagaService.indexEmpresa()).data.data
    } 
    function getCepSomenteNumeros(): string {
        return vaga.value.endereco!.endereco.cep.cep.replace(/\D/gim, '')
    }
    async function consultarEndereco(cep: string | undefined) { 
        if (cep && getCepSomenteNumeros().length === 8) {
            endereco.getFromCEP(getCepSomenteNumeros()).then((response: AxiosResponse<IEnderecoApi>) => { 
                vaga.value.endereco = {
                    endereco: response.data.data
                } as IUserEndereco
            })
        }
    }
    watch(
        () => filtroSelecionado.value.estado_id,
        (value: number | null) => {
            if (value != null) {
                loading.value.cidade = true
                getCidades(value)
                loading.value.cidade = false
            }
        },
        {
            deep: true
        }
    )

    return {
        filtros,
        filtroSelecionado,
        loading,
        vagas, 
        getTextoLocalVaga,
        getEstados,
        getCidades,
        save,  
        getCargos,
        getAreas,
        getSegmentos,
        getMostrarLogo,
        vagaAvatar,
        getVagas,
        getVagasEmpresa,
        consultarEndereco,
        vaga, 
        formData,
        newVaga,
        findVaga,
        diferencaEmDias,
        getCreateAtFormat,
        getLocalVagas
    }
})