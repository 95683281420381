<template>
  <div class="card">
    <div class="card-header">
      <div class="d-flex justify-content-center w-100">
        <span class="fs-32">Encontre a vaga perfeita!</span>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-lg-2 col-xl-1">
          <v-autocomplete
            :items="vagaStore.filtros.estados"
            item-title="sigla"
            item-value="id"
            label="UF"
            v-model="vagaStore.filtroSelecionado.estado_id"
          >
          </v-autocomplete>
        </div>
        <div class="col-lg-5 col-xl-3">
          <v-autocomplete
            :items="vagaStore.filtros.cidades"
            item-title="nome"
            item-value="id"
            label="Cidade"
            clearable
            hide-no-data
            v-model="vagaStore.filtroSelecionado.cidade_id"
            :loading="vagaStore.loading.cidade"
          >
          </v-autocomplete>
        </div>
        <div class="col-lg-5 col-xl-2">
          <v-autocomplete
            :items="vagaStore.filtros.cargos"
            item-title="nome"
            item-value="id"
            label="Cargo"
            clearable
            v-model="vagaStore.filtroSelecionado.cargo_id"
          >
          </v-autocomplete>
        </div>
        <div class="col-lg-6 col-xl-3">
          <v-autocomplete
            :items="vagaStore.filtros.areas"
            item-title="nome"
            item-value="id"
            label="Área"
            clearable
            v-model="vagaStore.filtroSelecionado.area_id"
          >
          </v-autocomplete>
        </div>
        <div class="col-lg-6 col-xl-3">
          <v-autocomplete
            :items="vagaStore.filtros.segmentos"
            item-title="nome"
            item-value="id"
            label="Segmento"
            clearable
            v-model="vagaStore.filtroSelecionado.segmento_id"
          >
          </v-autocomplete>
        </div>
      </div>
      <div class="row">
        <div
          class="col-sm-12 col-md-6 col-lg-4 col-xl-3"
          v-for="vaga in vagaStore.vagas"
          :key="vaga.id"
        >
          <card-vaga :vaga="vaga" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useVagaStore } from "@/stores/vagaStore";
import { onMounted } from "vue";
import CardVaga from "./CardVaga.vue";

const vagaStore = useVagaStore();

onMounted(() => {
  vagaStore.getEstados();
  vagaStore.getCargos();
  vagaStore.getAreas();
  vagaStore.getSegmentos();

  vagaStore.getVagas();
});
</script>
