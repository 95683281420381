import { Profile } from "@/enums/profileEnum";
import candidatoService from "@/services/candidatoService";
import userService from "@/services/userService";
import { useAuthStore } from "@/stores/authStore";
import { useCandidatoStore } from "@/stores/candidatoStore";
import { useRecrutadorStore } from "@/stores/recrutadorStore";
import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

export default async function notFirstAccess(next: NavigationGuardNext, to: RouteLocationNormalized, from: RouteLocationNormalized) {
    const authStore = useAuthStore()
    const recrutadorStore = useRecrutadorStore()

    let user = (await userService.getProfile()).data.data
    authStore.authUser = user; 
    switch (authStore.authUser.profile.id) {
        case Profile.CANDIDATO:  
        if (user.candidato?.funcoes && user.candidato.funcoes.length) {
            return next({
                name: 'home-candidato'
            })
        }
        break;

    case Profile.RECRUTADOR:   
        if (user.recrutador && user.recrutador.empresa_id) {  
            return next({
                name: 'home-recrutador'
            })
        }
            
    }
    return next()

}