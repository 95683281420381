import { Profile } from "@/enums/profileEnum";
import { useAuthStore } from "@/stores/authStore";
import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

export default function redirectHome(next: NavigationGuardNext, to: RouteLocationNormalized, from: RouteLocationNormalized) {
    const authStore = useAuthStore()

    let route: string

    switch (authStore.authUser.profile.id) {
        case Profile.CANDIDATO:
            route = 'home-candidato'
            break;

        case Profile.RECRUTADOR:
            route = 'home-recrutador'
            break;

        default:
            // aqui seria o redirect do administrador, como ainda não tem, estou jogando no login
            route = 'login'
            break;
    }

    return next({
        name: route
    })
}