import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from '@/App.vue'
import '@/css/main.scss'
import '@mdi/font/css/materialdesignicons.css';  



import router from './router'
import { vMaska } from 'maska'
import { createVuetify, ThemeDefinition } from 'vuetify'
import { aliases, fa } from 'vuetify/iconsets/fa'
import { mdi } from 'vuetify/iconsets/mdi'
// import * as components from 'vuetify/components'
// import * as directives from 'vuetify/directives'
import type { Router } from 'vue-router'
import { markRaw } from 'vue'    
// import 'bootstrap/dist/css/bootstrap.css'
import 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/js/bootstrap.bundle.min.js'

// definição de tema do izy-job
const izyJobTheme: ThemeDefinition = {
  dark: false,
  colors: {
    background: '#fc744c',
    surface: '#fff',
    color: '#fff',
    primary: '#fc744c',
    'primary-darken-1': '#3700B3',
    secondary: '#FF285C',
    'secondary-darken-1': '#018786',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
  }
}

const vuetify = createVuetify({
  // components,
  // directives,
  icons: {
    defaultSet: 'fa',
    aliases,
    sets: {
      fa,
      mdi,
    }
  },
  theme: {
    defaultTheme: 'izyJobTheme',
    themes: {
      izyJobTheme
    }
  },
})

declare module 'pinia' {
  export interface PiniaCustomProperties {
    router: Router;
  }
}

const pinia = createPinia()

pinia.use(({ store }) => {
  store.router = markRaw(router); 

})

const app = createApp(App)
app.use(pinia)
app.use(router) 
app.use(vuetify)
app.directive('maska', vMaska)
app.mount('#app')  
