import { IFiltroVaga, IVaga, IVagaApi, IVagaApiObject } from "@/interfaces/VagaInterface"
import { AxiosResponse } from "axios"
import API from "./http-common"
import { IImageApi } from "@/interfaces/ImageInterface"
import { IDefault, IDefaultApi } from "@/interfaces/SelectDefaultInterface"

class VagaService {
    index(filtros?: IFiltroVaga): Promise<AxiosResponse<IVagaApi>> {
        return API.post<IVagaApi>('/jobs/vagas', filtros)
    }
    indexEmpresa(): Promise<AxiosResponse<IVagaApi>> {
        return API.get<IVagaApi>('/vaga/lista')
    }
    create(vaga: IVaga): Promise<AxiosResponse<IVaga>> {
        return API.post('/vaga', vaga);
    }
    find(vagaId: number): Promise<AxiosResponse<IVagaApiObject>> {
        return API.get('/vaga/find/'+ vagaId)
    }
    uploadAvatar(formData: FormData): Promise<AxiosResponse<IImageApi>> { 
        return API.post('/vaga/avatar', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }
    getMostrarLogo() : Promise<AxiosResponse<IDefaultApi>>{
        return API.get<IDefaultApi>('/vaga/mostrarLogo')
    }
    update(vaga: IVaga,vagaId: number): Promise<AxiosResponse<IVagaApiObject>> {
        return API.post('/vaga/update/' + vagaId, vaga, {
            headers: { 
                'Content-Type': 'multipart/form-data', 
            }
        })
    } 
}

export default new VagaService()