import middlewarePipeline from '@/middleware/middlewarePipeline'
import { createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import errorRoutes from './errorRoutes'

import routes from './routes'
import type { Router } from 'vue-router'

const router: Router = createRouter({
    history: createWebHistory(),
    routes: [...routes, ...errorRoutes]
})

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    if (!to.meta.middleware) {  
        return next()
    }
    
    return middlewarePipeline(to.meta.middleware, next, to, from)
})

export default router